import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthManager, ProfileResponse} from 'shared';
import {map} from 'rxjs/operators';
import { AuthScopes } from 'shared/auth-scopes';
import {ReviewsComponent} from "../store/reviews/reviews.component";

const navigation = [
  {
    title: 'Back office',
    children: [
      // {
      //   title: 'Dashboard',
      //   url: '/',
      //   icon: 'dashboard'
      // },
      {
        title: 'Customers',
        url: '/customers',
        svgIcon: 'user-black'
      },
      {
        title: 'Devices',
        url: '/devices',
        svgIcon: 'firmwares-black'
      },
      {
        title: 'Walks',
        url: '/walks',
        svgIcon: 'walks-black'
      },
      {
        title: 'Achievements',
        url: '/achievements',
        svgIcon: 'trophy'
      },
      {
        title: 'Breeds',
        url: '/breeds',
        svgIcon: 'sound_detection_dog_barking'
      }
      // {
      //   title: 'Firmwares',
      //   svgIcon: 'firmwares-black',
      //   children: [
      //     {
      //       title: 'List',
      //       url: '/firmwares'
      //     },
      //     // {
      //     //   title: 'Statuses on devices',
      //     //   url: '/device-firmwares'
      //     // }
      //   ]
      // },
      // {
      //   title: 'Requests',
      //   url: '/requests',
      //   svgIcon: 'requests-black'
      // },
      // {
      //   title: 'Tracks',
      //   url: '/tracks',
      //   svgIcon: 'walks-black'
      // },

    ]
  },
  {
    title: 'Administration panel',
    children: [
      {
        title: 'Support',
        url: '/support',
        icon: 'support_agent',
        scopes: [AuthScopes.UsersManage]
      },
      {
        title: 'Orders',
        url: '/store/orders',
        icon: 'local_atm',
        scopes: [AuthScopes.OrdersManage, AuthScopes.OrdersRead]
      },
      {
        title: 'Reviews',
        url: '/store/reviews',
        icon: 'star_half',
        scopes: [AuthScopes.ReviewsManage, AuthScopes.ReviewsRead]
      }
    ]
  }
];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent {
  profile$: Observable<ProfileResponse>;
  navigation$: Observable<any[]>;

  constructor(private authManager: AuthManager) {
    const filterNavigations = (navigations: any[], scopes: string[]): any[] => {
      if (!scopes) { return navigations; }

      return navigations
        .map(item => ({...item}))
        .map(item => {
          if (item.children && item.children.length) {
            item.children = filterNavigations(item.children, scopes);
            if (!item.children.length) { return null; }
          }
          return item;
        })
        .filter(item => !!item)
        .filter(item => !item.scopes || item.scopes.some(r => scopes.indexOf(r) >= 0));
    };

    this.profile$ = authManager.profile$;
    this.navigation$ = authManager
      .profile$
      .pipe(map(
        profile => profile.roles && profile.roles.includes('Admin')
          ? navigation
          : filterNavigations(navigation, profile.scopes || [])
      ));
  }

  async signOut(): Promise<void> {
    await this.authManager.signOut();
  }
}
